// @ts-strict-ignore
/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/docs/ts/latest/guide/browser-support.html
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

import 'zone.js/dist/zone';
import 'zone.js/dist/zone-error';

// Angular 6 global shim
// Add global to window, assigning the value of window itself.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).global = window;

// NodeJS process
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).process = {
  env: { DEBUG: undefined },
};

// Helpscout shim
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logError: (...data: any[]) => void;
  }
}

// Polyfill for Promise.withResolvers
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
if (Promise.withResolvers === undefined) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Promise.withResolvers = (): { promise: Promise<unknown>, resolve: (value: unknown) => void, reject: (value: unknown) => void } => {
    let resolve: (value: unknown) => void = () => { /* do nothing */};
    let reject: (value: unknown) => void = () => { /* do nothing */ };
    const promise = new Promise((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

/** in polyfills.ts */
// Set PDFJS global worker
import * as pdfjs from 'pdfjs-dist';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.min.mjs';

// Mobile drag and drop shim
import { polyfill } from 'mobile-drag-drop';

// optional import of scroll behaviour
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';

// options are optional ;)
polyfill({
  // use this to make use of the scroll behaviour
  dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

/* IE10 and IE11 requires the following for the Reflect API. */
// import 'core-js/es6/reflect';

/* Evergreen browsers require these. */
// Used for reflect-metadata in JIT. If you use AOT (and only Angular decorators), you can remove.
import 'core-js/es7/reflect'; // Run `npm install --save web-animations-js`.

/***************************************************************************************************
 * Zone JS is required by Angular itself.
 */
import 'zone.js/dist/zone'; // Included with Angular CLI.

import 'zone.js/dist/zone-error'; // Adds zone debugging information.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */

/*
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl';  // Run `npm install --save intl`.
/*
 * Need to import at least one locale-data with intl.
 */
// import 'intl/locale-data/jsonp/en';
